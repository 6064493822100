<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">      
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    <!--detail_indication start-->
                    <div class="content-onglets collapse show" v-if="key == 'detail_RtuAnsm' && item.show" >                        
                        <div v-for="(items,keys) in data.rtu.rtu_ansm" :key="'rtu_amsn'+keys" >
                            <div v-if="items.avis" style='color:black;font-weight:bold;' > 
                                {{$t(items.avis)}}
                            </div>
                            <div v-if="items.date_avis" style='color:black;font-weight:bold;' >
                                 {{DDMMMYY(items.date_avis)}} 
                            </div>
                        </div>
                        <table v-if="chkRtuAnsm_commit" class="comparateur2" >
                            <thead>
                                <tr>
                                    <th>{{$t('Avis du comité scientifique')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  v-for="(items,keys) in data.rtu.rtu_ansm" :key="'rtu_amsn2'+keys">
                                    <td style="text-align: justify;"><div v-html="items['avis_commentaire_'+lang]"></div></td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="comparateur2">
                            <thead>
                                <tr>
                                    <th>{{$t('Octroi')}}</th>
                                    <th>{{$t('rtu_date_start')}}</th>
                                    <th>{{$t('rtu_date_end')}}</th>
                                    <th>{{$t('link')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr  v-for="(items,keys) in data.rtu.rtu_ansm" :key="'rtu_amsn3'+keys">
                                    <td>{{ items['date_octroi']? DDMMMYY(items['date_octroi']) : "-" }}</td>
                                    <td>{{ items['date_start']? DDMMMYY(items['date_start']) : "-" }}</td>
                                    <td>{{ items['date_end']? DDMMMYY(items['date_end']) : "-" }}</td>
                            
                                    
                                    <td> 
                                        <a v-if="items.lien" :href="items.lien" target="_blank" rel="noopener"><div class="icon-fiche"></div></a>
                                        <span v-else> - </span>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                        <table v-if="chkRtuAnsm" class="comparateur2">
                            <thead>
                                <tr>
                                    <th>{{$t('Indication retenue dans Ia RTU')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr  v-for="(items,keys) in data.rtu.rtu_ansm" :key="'rtu_amsn4'+keys">
                                    <td style="text-align: justify;"><span v-html="items['ansm_' + lang]"></span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!--indication start-->
                    <Indication v-else-if="key == 'detail_indication' && item.show"/>
                    <!--indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!--absence_dalternative start-->                     
                    <table v-else-if="key == 'detail_absence_dalternative' && item.show" class="comparateur2" > 
                        <tbody>  
                            <tr>
                                <th width="50%" style="text-align: left !important;">{{$t('maladie_rare')}}</th>
                                <td>{{ data.rtu.maladie_rare ? "X" : "-"}} </td>
                            </tr> 
                            <tr>
                                <th class="text-left">{{$t('critere_txt1')}}</th>
                                <td>{{ data.rtu.absence_dalternative ? "X" : "-"}} </td>                                
                            </tr> 
                            <tr>
                                <th class="text-left">{{$t('critere_txt2')}}</th>                                
                                <td>{{ data.rtu.utilisation ? "X" : "-"}} </td>
                            </tr> 
                            <tr>
                                <th class="text-left">{{$t('critere_txt3')}}</th>                                
                                <td>{{ check_empty(data.rtu.autre_text) }} </td>   
                            </tr>                     
                        </tbody>
                    </table>     
                    <!--absence_dalternative end--> 
                    
                    <!-- detail_Contexte start -->
                    <p v-else-if="key == 'detail_Contexte' && item.show">
                        <span v-html="data.rtu.contexte"></span>
                    </p>
                    <!-- detail_Contexte end -->

                    <!-- detail_La_maladie start -->
                    <p v-else-if="key == 'detail_La_maladie' && item.show">
                        <span v-html="data.rtu.la_maladie"></span>
                    </p>
                    <!-- detail_La_maladie end -->

                    <!-- detail_strategie_therapeutique start -->
                    <p v-else-if="key == 'detail_strategie_therapeutique' && item.show">
                        <span v-html="data.rtu.strategie_therapeutique"></span>
                    </p>
                    <!-- detail_strategie_therapeutique end -->

                    <!-- detail_interet_specialite start -->
                    <p v-else-if="key == 'detail_interet_specialite' && item.show">
                        <span v-html="data.rtu.interet_specialite"></span>
                    </p>
                    <!-- detail_interet_specialite end -->

                     <!-- resume_discussion -->
                    <p v-else-if="key == 'detail_resume_discussion' && item.show" >
                        <span v-html="data.rtu.resume_discussion"></span>
                    </p>

                    <!--Population concernée start--> 
                    <table v-else-if="key == 'detail_population_concern' && item.show" class="comparateur2">
                        <tbody>
                            <tr>
                                <td width="100%" class="text-left" colspan="2">
                                    <span v-html="data.rtu.population_concern"></span>
                                </td>
                            </tr> 
                            <tr>                                                    
                                <th width="50%" style="text-align: center;">{{$t('nombre de patients concernes')}} :</th>
                                <td>{{ check_empty(data.rtu.nombre_de_patients_concernes) }}</td>
                            </tr>
                        </tbody>
                    </table>     
                    <!--Population concernée end--> 

                    <!-- detail_conclusion -->
                    <p v-else-if="key == 'detail_conclusion' && item.show" >
                        <span v-html="data.rtu.conclusion"></span>
                    </p>

                    <!-- commentaires  -->
                    <p v-else-if="key == 'detail_commentaires' && item.show" >
                        <span v-html="data.rtu.commentaires"></span>
                    </p>

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                    <!--  evaluation_essais_clinique -->  
                    <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                   
                    <!-- Section Detail End -->

                </div>

            </template>    

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			
			
        </div>
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_empty, key_documents } from '../../../utils'

export default{
    name: 'rtu',
    components : {
        LeftSection,
        TreatmentLine,
        EconomicEvaluation,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_RtuAnsm : { 'title' : 'ANSM', 'show' : true, 'enable' : false, 'orange_text':false},  
                detail_indication: { 'title' : 'indication', 'show' : true, 'enable' : false, 'orange_text':false},  
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_absence_dalternative : {'title' : 'rtu prise en charge', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_Contexte : {'title' : 'Contexte', 'show' : true, 'enable' : false, 'orange_text':false },        
                detail_La_maladie : {'title' : 'La maladie', 'show' : true, 'enable' : false, 'orange_text':false },        
                detail_strategie_therapeutique : {'title' : 'Stratégie thérapeutique', 'show' : true, 'enable' : false, 'orange_text':false },        
                detail_interet_specialite : {'title' : 'Intérêt de la spécialité pour les patients', 'show' : true, 'enable' : false, 'orange_text':false },               
                detail_resume_discussion : {'title' : 'Resume et discussion', 'show' : true, 'enable' : false, 'orange_text':false },   
                detail_population_concern : {'title' : 'Population concernée', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_conclusion : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_commentaires : {'title' : 'rtu Commentaires', 'show' : true, 'enable' : false, 'orange_text':false },   
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },  
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },  
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },            
            } ,
            chkRtuAnsm : false,
            chkRtuAnsm_commit : false,
            lang : 'fr',
            
        }
    },
    created(){  
        // condition to show check box in left section [enable]
        if(this.data && this.data.rtu){
                
            this.lang = this.$i18n.locale == 'de' ? 'en' : this.$i18n.locale
            
            for (let key in this.data.rtu.rtu_ansm){
                if (this.data.rtu.rtu_ansm[key]['avis_commentaire_'+this.lang]){
                    this.chkRtuAnsm_commit = true
                }
                if (this.data.rtu.rtu_ansm[key]['ansm_'+this.lang]){
                    this.chkRtuAnsm = true
                }
            }

            if (this.data.rtu.rtu_ansm.length > 0) {
                this.list_check_box['detail_RtuAnsm'].enable = true
            }

            if (this.data['indication_en']) { 
                this.list_check_box['detail_indication'].enable = true
            }

            if (this.data.rtu.treatment_line || this.data.rtu.treatment_specificity || this.data.rtu.treatment_administration ) { 
                this.list_check_box['treatment_line'].enable = true
            }

            if (this.data.rtu.absence_dalternative || this.data.rtu.utilisation || this.data.rtu.autre_text ) { 
                this.list_check_box['detail_absence_dalternative'].enable = true
            }

            if(this.data.rtu.contexte){ 
                this.list_check_box['detail_Contexte'].enable = true
            }
            if(this.data.rtu.la_maladie){ 
                this.list_check_box['detail_La_maladie'].enable = true
            }
            if(this.data.rtu.strategie_therapeutique){ 
                this.list_check_box['detail_strategie_therapeutique'].enable = true
            }
            if(this.data.rtu.interet_specialite){ 
                this.list_check_box['detail_interet_specialite'].enable = true
            }

            if (this.data.rtu.population_concern || this.data.rtu.nombre_de_patients_concernes) {
                this.list_check_box['detail_population_concern'].enable = true                    
            }       

            if (this.data.rtu.conclusion) {                 
                this.list_check_box['detail_conclusion'].enable = true
            }

            if (this.data.rtu.commentaires){
                this.list_check_box['detail_commentaires'].enable = true
            }

            if (this.data.rtu.resume_discussion){
                this.list_check_box['detail_resume_discussion'].enable = true
            }

            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
            }                 
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }                
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
                    
        }
    },
    methods : {
        DDMMMYY,
        check_empty,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }       
    },
}

</script>

<style>

</style>